import React from "react";
var __jsx = React.createElement;
import { useTranslation, Trans } from 'react-i18next';
import { useModalToggle, useModalOpened } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import Modal from "../Modal";
import Button from "../Button/PrimaryButton";
import { LOCALES } from "../../locales";
import styled from 'styled-components';
import IconWarning from "../../assets/images/warning-black.svg";
export var IpBlockedModal = function IpBlockedModal() {
  var isOpen = useModalOpened(ApplicationModal.IP_BLOCKED);
  var toggleModal = useModalToggle(ApplicationModal.IP_BLOCKED);

  var _useTranslation = useTranslation(),
      t = _useTranslation.t,
      i18n = _useTranslation.i18n;

  var isZh = i18n.resolvedLanguage === LOCALES.ZH;
  var lang = isZh ? 'zh-cn' : 'en-us';
  var link = "https://support.tokenlon.im/hc/".concat(lang, "/articles/35824650452116");
  return __jsx(Modal, {
    isOpen: isOpen,
    onDismiss: toggleModal,
    animate: false,
    contentStyle: {
      maxWidth: '500px',
      padding: '32px'
    }
  }, __jsx(StyledContainer, null, __jsx(StyledContent, null, __jsx(StyledIcon, {
    src: IconWarning,
    alt: "warning"
  }), __jsx(StyledTitle, null, t('ip_blocked_modal.title')), __jsx(StyledDesc, null, __jsx(Trans, {
    i18nKey: "ip_blocked_modal.desc"
  }, "\u62B1\u6B49\uFF0C\u8BE5\u529F\u80FD\u65E0\u6CD5\u5728\u5F53\u524D\u5730\u533A\u4F7F\u7528\uFF0C\u656C\u8BF7\u8C05\u89E3\u3002", __jsx("a", {
    href: link,
    target: "_blank"
  }, "\u4E86\u89E3\u66F4\u591A"), "\u3002"))), __jsx(Button, {
    text: t('ip_blocked_modal.btn'),
    handleClick: toggleModal,
    style: {
      maxWidth: '300px',
      marginTop: '20px'
    }
  })));
};
var StyledContainer = styled.div.withConfig({
  displayName: "StyledContainer",
  componentId: "sc-1q48f2s-0"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;"]);
var StyledContent = styled.div.withConfig({
  displayName: "StyledContent",
  componentId: "sc-1q48f2s-1"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;gap:8px;padding:24px;border-radius:20px;background-color:#f7f7f9;p{margin:0;}"]);
var StyledTitle = styled.p.withConfig({
  displayName: "StyledTitle",
  componentId: "sc-1q48f2s-2"
})(["font-size:16px;font-weight:600;color:#3c3c44;"]);
var StyledDesc = styled.p.withConfig({
  displayName: "StyledDesc",
  componentId: "sc-1q48f2s-3"
})(["font-size:14px;font-weight:400;color:#81808d;a{color:", ";cursor:pointer;:hover{color:", ";}}"], function (_ref) {
  var theme = _ref.theme;
  return theme.colors.linkPrimary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.linkPrimary;
});
var StyledIcon = styled.img.withConfig({
  displayName: "StyledIcon",
  componentId: "sc-1q48f2s-4"
})(["width:24px;height:24px;"]);