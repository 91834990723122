import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { StyledPrimaryButton } from "./styled";

var PrimaryButton = function PrimaryButton(_ref) {
  var text = _ref.text,
      style = _ref.style,
      disabled = _ref.disabled,
      handleClick = _ref.handleClick;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var textLabel = text !== null && text !== void 0 ? text : t('ok');
  return __jsx(StyledPrimaryButton, {
    style: style,
    disabled: disabled,
    onClick: handleClick
  }, textLabel);
};

export default PrimaryButton;