import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _TokenlonEnv$developm;

import Axios from 'axios';
import { captureException } from '@sentry/nextjs';
import { RUNTIME_ENV, TokenlonEnv } from "../constants/env";
import { API_ROOT_DOMAIN, BIZ_API_HOST } from "../constants/rootDomain";
export var API_BASE_URL = "https://api.".concat(API_ROOT_DOMAIN, "/v1");
export var MARKET_API_BASE_URL = "https://tokenlon-market.".concat(API_ROOT_DOMAIN, "/rpc");
export var EXCHANGE_API_BASE_URL = "https://exchange.".concat(API_ROOT_DOMAIN, "/rpc");
export var RAILS_API_BASE_URL = "https://".concat(BIZ_API_HOST, "/v1/business");
export var AUTH_TOKEN = (_TokenlonEnv$developm = {}, _defineProperty(_TokenlonEnv$developm, TokenlonEnv.development, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkYwNEE4RTgxLTkzNDItNDM0OS1BRkRFLTRCMjYwQTkyRkEzOSIsImp0aSI6ImltMTR4NTdLQ3hIeldyUDhtSzFVTTJLN2VBSm5UVG9jOVhuWEp4ViJ9.L7cX_cxbisH12q1wtivtMEm6FC3hSWMkjwVE7o_z-Hw'), _defineProperty(_TokenlonEnv$developm, TokenlonEnv.local, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkYwNEE4RTgxLTkzNDItNDM0OS1BRkRFLTRCMjYwQTkyRkEzOSIsImp0aSI6ImltMTR4NTdLQ3hIeldyUDhtSzFVTTJLN2VBSm5UVG9jOVhuWEp4ViJ9.L7cX_cxbisH12q1wtivtMEm6FC3hSWMkjwVE7o_z-Hw'), _defineProperty(_TokenlonEnv$developm, TokenlonEnv.staging, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkVBQjZBRTJELThFNEYtNEFDMS1CODM4LTA5MkQwMzE2NjlGQSIsImp0aSI6ImltMTR4NUxZck11Q1lxaXdTRzVBeFhaOXlGRDlIdml2VmJKdDVMRiJ9.rkJ2jziqRKwHvUKX2xkrkA2CDppGegElgVuZ2syHf5Y'), _defineProperty(_TokenlonEnv$developm, TokenlonEnv.production, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUb2tlbiI6IkVBQjZBRTJELThFNEYtNEFDMS1CODM4LTA5MkQwMzE2NjlGQSIsImp0aSI6ImltMTR4NUxZck11Q1lxaXdTRzVBeFhaOXlGRDlIdml2VmJKdDVMRiJ9.rkJ2jziqRKwHvUKX2xkrkA2CDppGegElgVuZ2syHf5Y'), _TokenlonEnv$developm)[RUNTIME_ENV];
export var CORE_MARKET_URL = 'https://tokenlon-core-market.tokenlon.im/rpc';
var axios = Axios.create({
  baseURL: API_BASE_URL
});
axios.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  captureException(error);
  return Promise.reject(error);
});
var rpc = Axios.create({
  baseURL: API_BASE_URL
});
rpc.interceptors.response.use(function (response) {
  return response.data.result;
}, function (error) {
  captureException(error);
  return Promise.reject(error);
});
export { axios, rpc };