import _extends from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"],
    _excluded2 = ["children"];
import React from "react";
var __jsx = React.createElement;
import styled, { css, ThemeProvider as StyledThemeProvider } from 'styled-components'; ////////////////////////////////////////////////////////////////////////////////////

/**
 * Media Queries
 */

export var MediaBreakPointUp = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};
export var MediaBreakPoint;

(function (MediaBreakPoint) {
  MediaBreakPoint["SM"] = "sm";
  MediaBreakPoint["MD"] = "md";
  MediaBreakPoint["LG"] = "lg";
  MediaBreakPoint["XL"] = "xl";
})(MediaBreakPoint || (MediaBreakPoint = {}));

var mediaBreakpointUpTemplates = Object.keys(MediaBreakPointUp).reduce(function (accumulator, size) {
  ;

  accumulator[size] = function (a, b, c) {
    return css(["@media (max-width:", "){", "}"], MediaBreakPointUp[size], css(a, b, c));
  };

  return accumulator;
}, {}); ////////////////////////////////////////////////////////////////////////////////////

/**
 * Typography
 */

var wrapPx = function wrapPx(str) {
  return typeof str === 'number' ? "".concat(str, "px") : str;
};

export var TextWrapper = styled.div.withConfig({
  displayName: "TextWrapper",
  componentId: "sc-gq486i-0"
})(["color:", ";font-size:", ";line-height:", ";font-weight:", ";"], function (_ref) {
  var color = _ref.color;
  return color || 'rgba(0, 0, 0, 0.85)';
}, function (_ref2) {
  var fontSize = _ref2.fontSize;
  return wrapPx(fontSize) || '14px';
}, function (_ref3) {
  var lineHeight = _ref3.lineHeight;
  return wrapPx(lineHeight) || 1;
}, function (_ref4) {
  var fontWeight = _ref4.fontWeight;
  return fontWeight || 'normal';
});
export var Type = {
  Label: function Label(_ref5) {
    var children = _ref5.children,
        props = _objectWithoutProperties(_ref5, _excluded);

    return __jsx(TextWrapper, _extends({
      color: "#9598ab",
      fontSize: 13,
      lineHeight: 18
    }, props), children);
  },
  Address: function Address(_ref6) {
    var children = _ref6.children,
        props = _objectWithoutProperties(_ref6, _excluded2);

    return __jsx(TextWrapper, _extends({
      color: "#191c1e",
      fontSize: 15,
      lineHeight: 18,
      fontWeight: 500
    }, props), children);
  }
};

var theme = function theme() {
  return {
    // media queries
    media: mediaBreakpointUpTemplates,
    colors: {
      primary: '#5f4dbc',
      bgColor: '#f6f8fb',
      homeBgColor: 'linear-gradient(180deg,rgba(209, 215, 255, 0) 0%,   #e4e8ff 100%)',
      headerNormal: '#fff',
      footerNormal: '#f6f8fb',
      btnPrimary: '#5f4dbc',
      btnPrimaryDisabled: 'rgba(95, 77, 188, 0.4)',
      exchangeBtnDisabled: '#BEB8E2',
      btnTextPrimary: '#fff',
      btnSecondary: '#ffffff',
      btnTextSecondary: '#604DBC',
      textBlack: '#191c1e',
      textBlack2: '#2c2842',
      textGrey: '#9598ab',
      textGrey2: '#6f7390',
      linkPrimary: '#5f4dbc',
      alertInfoText: '#006BD6',
      alertInfoBg: '#D6EBFF',
      alertWarningText: '#D6AB3C',
      alertWarningBg: '#FFF7E1',
      alertRiskText: '#D66665',
      alertRiskBg: '#FFEAE9',
      alertVerifiedText: '#3AC073',
      alertVerifiedBg: '#EBF9F1'
    }
  };
};

var ThemeProvider = function ThemeProvider(_ref7) {
  var children = _ref7.children;
  return __jsx(StyledThemeProvider, {
    theme: theme()
  }, children);
};

export default ThemeProvider;