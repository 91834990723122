import styled from 'styled-components';
export var StyledButton = styled.button.withConfig({
  displayName: "StyledButton",
  componentId: "sc-18h0s17-0"
})(["background:#5f4dbc;border-radius:48px;border:none;outline:none;font-weight:500;font-size:16px;line-height:22px;color:#ffffff;padding:12px 0;width:100%;cursor:pointer;"]);
export var StyledPrimaryButton = styled.button.withConfig({
  displayName: "StyledPrimaryButton",
  componentId: "sc-18h0s17-1"
})(["display:flex;justify-content:center;align-items:center;width:100%;padding:12px 0;border-radius:48px;border:none;outline:none;background:", ";font-weight:500;font-size:16px;line-height:22px;color:#ffffff;cursor:pointer;"], function (_ref) {
  var theme = _ref.theme,
      disabled = _ref.disabled;
  return disabled ? theme.colors.btnPrimaryDisabled : theme.colors.btnPrimary;
});